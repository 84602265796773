// import React, { useState, useRef } from 'react';
// import './NewBanner.css';

// const NewBanner = () => {
 
//     const [isRecording, setIsRecording] = useState(false);
//     const [audioUrl, setAudioUrl] = useState(null);
//     const mediaRecorderRef = useRef(null);
//     const audioChunksRef = useRef([]);
  
//     const startRecording = async () => {
//       setIsRecording(true);
//       audioChunksRef.current = []; // Reset audio chunks
  
//       try {
//         // Request microphone access
//         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//         mediaRecorderRef.current = new MediaRecorder(stream);
  
//         mediaRecorderRef.current.ondataavailable = (event) => {
//           audioChunksRef.current.push(event.data);
//         };
  
//         mediaRecorderRef.current.onstop = () => {
//           const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
//           const audioUrl = URL.createObjectURL(audioBlob);
//           setAudioUrl(audioUrl);
//           uploadAudio(audioBlob); // Call function to upload audio to your backend
//         };
  
//         mediaRecorderRef.current.start();
//       } catch (error) {
//         console.error('Error accessing microphone:', error);
//       }
//     };
  
//     const stopRecording = () => {
//       setIsRecording(false);
//       if (mediaRecorderRef.current) {
//         mediaRecorderRef.current.stop();
//       }
//     };
  
//     const uploadAudio = async (audioBlob) => {
//       const formData = new FormData();
//       formData.append('audio', audioBlob, 'recording.wav'); // 'recording.wav' is the file name you can adjust
  
//       try {
//         // Send audio to the backend
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/send-audio`, {
//           method: 'POST',
//           body: formData, // Send FormData with audio Blob
//         });
  
//         const data = await response.json();
//         console.log('Transcription:', data.transcription);
//         console.log('Audio URL:', data.audioUrl);
//         setAudioUrl(data.audioUrl); // Set the URL for the recorded audio
//       } catch (error) {
//         console.error('Error uploading audio:', error);
//       }
//     };

//   return (
//     <div className='divp' >
//             {/* <h1 className='siz text-center py-lg-5 mx-2 py-3 ' style={{ lineHeight:'55px'}}>AI Virtual <span className='h1bubble px-4 py-0 rounded-full'>Ops</span></h1> */}
//             <h1 className='siz text-center  mx-2 py-0 ' ><span className='h1bubble px-4 py-0 mx-1 rounded-full'>AI </span> enhanced <span className='h1bubble2 px-4 py-0 mx-1 rounded-full'> Virtual Assistants </span><br/> for running Your <br/><span className='h1bubble3 mx-1 px-4 py-0 rounded-full'>Business</span> & <span className='h1bubble3 mx-1 px-4 py-0 rounded-full'> Operations</span></h1>
//             <div className='py-lg-5 py-2 mx-2'>
//                 <p className='sizp text-center text-gray-500'>Elevate your business with our high-end virtual assistant services, designed to seamlessly support your administrative and operational needs, ensuring efficiency and excellence.</p>
//             {/* <p className='sizp text-center text-gray-500'>Elevating Businesses with AI-Powered Virtual Assistance</p> */}
//             {/* <p className='sizp text-center text-gray-500'>
//                 – Where Success Takes Flight
//             </p> */}
//             </div>
//             <h1>Audio Recorder</h1>
//       {isRecording ? (
//         <button onClick={stopRecording}>Stop Recording</button>
//       ) : (
//         <button onClick={startRecording}>Start Recording</button>
//       )}
//       {audioUrl && <audio controls src={audioUrl} />}
//         </div>
//   );
// };

// export default NewBanner;


import React, { useState, useRef, useEffect } from 'react';
import './NewBanner.css';

const NewBanner = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [base64Audio, setBase64Audio] = useState(null); // State for base64 audio
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    setIsRecording(true);
    audioChunksRef.current = []; // Reset audio chunks

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        uploadAudio(audioBlob); // Call function to upload audio to your backend
      };

      mediaRecorderRef.current.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const uploadAudio = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

 
      const response = await fetch(`${process.env.REACT_APP_URL}/api/send-audio`, {
        method: 'POST',
        body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
        const { transcript, audio } = data;
    
        // Display the transcript
        console.log("Transcript:", transcript);
    
        // Convert base64 audio to Blob and play it
        const audioBlob = new Blob([Uint8Array.from(atob(audio), c => c.charCodeAt(0))], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audioElement = new Audio(audioUrl);
        // audioElement.play();
      })
      .catch((error) => {
        console.error('Error fetching audio:', error);
      });}




  return (
    <div className='divp'>
      <h1 className='siz text-center mx-2 py-0'>
        <span className='h1bubble px-4 py-0 mx-1 rounded-full'>AI </span> enhanced 
        <span className='h1bubble2 px-4 py-0 mx-1 rounded-full'> Virtual Assistants </span><br/>
        for running Your <br/>
        <span className='h1bubble3 mx-1 px-4 py-0 rounded-full'>Business</span> & 
        <span className='h1bubble3 mx-1 px-4 py-0 rounded-full'> Operations</span>
      </h1>
      <div className='py-lg-5 py-2 mx-2'>
        <p className='sizp text-center text-gray-500'>Elevate your business with our high-end virtual assistant services, designed to seamlessly support your administrative and operational needs, ensuring efficiency and excellence.</p>
      </div>
      {/* <h1>Audio Recorder</h1>
      {isRecording ? (
        <button onClick={stopRecording}>Stop Recording</button>
      ) : (
        <button onClick={startRecording}>Start Recording</button>
      )}
      {base64Audio &&  <source src={base64Audio}  />}
      {base64Audio && (
        <audio controls>
          <source src={base64Audio}  />
          Your browser does not support the audio element.
        </audio> */}
      {/* )} */}
    </div>
  );
};

export default NewBanner;



