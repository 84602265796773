import React, { useEffect, useState, useRef } from 'react';
import './chatCss.css';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2';
import { BsStopCircle } from 'react-icons/bs';
import sendIcon from './send.webp';
import PCMPlayer from 'pcm-player'

import { io } from 'socket.io-client';

function TypingEffect({ text }) {
  return <div>{text}</div>;
}

const TextChat = () => {

  const [transcript, setTranscript] = useState('');
  // const [audioChunks, setAudioChunks] = useState([]);

  let audioContext; // Singleton for AudioContext

  const getAudioContext = () => {
    if (!audioContext) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Create an AudioContext
    }
    return audioContext; // Return the singleton instance
  };

  const player = new PCMPlayer({
    inputCodec: 'Int16',
    channels: 1,
    sampleRate: 24000,
    flushTime: 200 // Lower to 100-200 ms if 2000 ms causes lag
  });


  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64); // Decode the base64 string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer; // Return the ArrayBuffer
  };

  const [canswer, setCanswer] = useState('')
  const [fullMessage, setFullMessage] = useState(""); // Use state for fullMessage
  const [socketOnChk,setSocketOnChk]=useState(false)

  const socket = io('https://aivirtualops.com');
  // const socket = io('http://localhost:5000');

  const audioContextRef = useRef(null);
  useEffect(() => {
    socket.on('connect', () => {
      setSocketOnChk(true)
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      setSocketOnChk(false)
      console.log('Disconnected from server');
    });

  audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    // Event listener for incoming audio chunks from socket
  // socket.on('audio', (audioChunk) => {
  //     // Decode the base64 audio chunk
  //     const byteCharacters = atob(audioChunk); // Decode base64 to binary
  //     const byteNumbers = new Uint8Array(byteCharacters.length);

  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     // Convert Uint8Array to Int16Array if needed
  //     const int16Array = new Int16Array(byteNumbers.buffer); // If your PCM data is in 16-bit format

  //     // Feed PCM data into the player
  //     try {
  //       console.log('pcm=====', int16Array)
  //       player.feed(int16Array);
  //     } catch (error) {
  //       console.error('Error feeding data to PCMPlayer:', error);
  //     }
  //   });

  let audioQueue = [];
  let isPlaying = false;
  
  // Function to process the audio queue

    function processQueue() {
    if (audioQueue.length > 0 && !isPlaying) {
      isPlaying = true;
      const audioChunk = audioQueue.shift(); // Get the next chunk
      player.feed(audioChunk);               // Feed it to the player
  
      // Set a timeout based on the chunk duration to allow feeding the next chunk
      setTimeout(() => {
        isPlaying = false;
        processQueue(); // Process the next chunk in the queue
      }, audioChunk.length / player.sampleRate * 1000); // Adjust timeout based on chunk duration
    }
  }
  
  // Socket listener for audio data
  socket.on('audio', (audioChunk) => {
    // Decode the base64 audio chunk
    const byteCharacters = atob(audioChunk);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    
    // Convert Uint8Array to Int16Array if needed
    const int16Array = new Int16Array(byteNumbers.buffer); // For 16-bit PCM data
    
    // Add to the queue and start processing if not already
    audioQueue.push(int16Array);
    processQueue();
  });

    const handleTextReceived = (text) => {
      console.log('fill ms ', fullMessage, "--", inputValue, "----", canswer);

      // Reset inputValue and canswer
      setInputValue('');
      setCanswer('');

      console.log('Received text transcript:', text);

      // If you want to start fresh, reset fullMessage before concatenation
      // Alternatively, you can keep the resetting logic based on your specific use case
      setFullMessage(prevMessage => {
        // Concatenate the new fragment to the full message
        const newFullMessage = prevMessage + text;
        setCanswer(newFullMessage);
        return newFullMessage; // Update the state with the new full message
      });
    };

    socket.on('text', handleTextReceived);
   
    // Cleanup on component unmount
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('audio');
      socket.off('text');
      setSocketOnChk(false)
    };
  }, []);

  const playAudio = (audioChunk) => {
    audioContextRef.current.decodeAudioData(audioChunk, (buffer) => {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    }, (error) => {
      console.error('Error decoding audio data:', error);
    });
  };

  // const mediaRecorderRef = useRef(null);
  // useEffect(() => {
  //   if (socketOnChk) {
  //     console.log('listening')
  //     // Setup audio recording
  //     navigator.mediaDevices.getUserMedia({ audio: true })
  //       .then(stream => {
  //         mediaRecorderRef.current = new MediaRecorder(stream);

  //         mediaRecorderRef.current.ondataavailable = (event) => {
  //           if (event.data.size > 0 && socket) {
  //             // Send audio data through WebSocket
  //             socket.emit('audiolisten', event.data);
  //           }
  //         };

  //         mediaRecorderRef.current.start(100); // Start recording and send data every 100ms
  //       })
  //       .catch(error => {
  //         console.error('Error accessing audio stream:', error);
  //       });

  //     // Cleanup function to stop recording when the component unmounts
  //     return () => {
  //       if (mediaRecorderRef.current) {
  //         mediaRecorderRef.current.stop();
  //       }
  //     };
  //   }
  // }, [socketOnChk]);


  const BACKEND = process.env.REACT_APP_URL;
  const audioRef = useRef(null);

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [speechTog, setSpeechTog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioSub, setAudioSub] = useState(false);
  const [chkk, setChkk] = useState('');
  const [chatbotMsg, setChatbotMsg] = useState('');
  const [audioSrc, setAudioSrc] = useState('')

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.');
    }
  };

  const mimeType = 'audio/webm';
  const startRecording = async () => {
    setRecordingStatus('recording');
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    console.log('stream in start 2',mediaRecorder.current)
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
    console.log('audio chunk start ',localAudioChunks)
  };

  const stopRecording = () => {
    console.log('audio chunk stop ',audioChunks)
    setLoading(true);
    setRecordingStatus('inactive');
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      setAudio(audioBlob);
      setAudioChunks([]);

      if (audioBlob) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');
      
        console.log("Audio Blob:", audioBlob);
        console.log("Form Data:", formData.get('audio'));

        // Immediately add the message before the response is received
        const newMessageI = {
          id: messages.length,
          text: inputValue, // This should be the query you sent
          sender: 'me',
        };
        if(newMessageI.text !==''){
          setMessages((prevMessages) => [...prevMessages, newMessageI]);
        }

        axios
          .post(`${BACKEND}/api/send-audio`, formData, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then((res) => {
            console.log('res.data ', res.data);
            setLoading(false);
            // Update the input value with the response transcript
            setInputValue(res.data.transcript);

            // If you want to handle the chatbot response, you can also add it here

            const chatbotMessage = {
              id: messages.length + 1, // Incrementing ID for chatbot message
              text: res.data.input, // Chatbot response text
              sender: 'me', // Assuming sender is empty for chatbot
            };
            if(chatbotMessage !==''){
              setMessages((prevMessages) => [...prevMessages, chatbotMessage]);
            }

            const uniqueParam = new Date().getTime();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });

      }
    };
  };

  useEffect(() => {
 console.log('message======= ',messages)
    console.log('----------220 ', messages[messages.length - 1])
    if (messages[messages.length - 1]?.sender === 'me' && canswer !== messages[messages.length - 1]?.text) {
      console.log('----------230 ')
      const chatbotMessage = {
        id: messages.length, // Incrementing ID for chatbot message
        text: inputValue, // Chatbot response text
        sender: '', // Assuming sender is empty for chatbot
      };
      if(chatbotMessage.text !== ''){
        setMessages((prevMessages) => [...prevMessages, chatbotMessage]);
      }
      setCanswer('')
      setInputValue('')

    }
  }, [messages])

  const handleToggleSpeech = () => {
    setSpeechTog((prevTog) => !prevTog);
  };

  const messageStyleSend = {
    backgroundColor: '#f0f8ff',
    fontSize: '16px',
    color: '#000000',
    fontFamily: 'Arial, sans-serif',
  };

  const messageStyleRec = {
    backgroundColor: '#e6e6fa',
    fontSize: '16px',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  };





  return (
    <div>
      <div className=' ' style={{ paddingTop: '100px', paddingBottom: '100px', minWidth: '90vw' }}>
        <h1 className=" flex-wrap col-12 d-flex justify-content-center text-center" style={{ color: 'black' }}>Speech to Speech Demo</h1>
        <div className='col-12 flex justify-center'>
          <div className="row d-flex justify-content-around flex-wrap" style={{ minWidth: '90vh' }}>
            <div className="d-flex justify-content-center my-5 col-lg-10 col-11">
              <div
                className="d-flex"
                style={{
                  position: 'relative',
                  height: '600px',
                  minWidth: '240px',
                  maxWidth: '90vw',
                  width: '100%',
                  marginTop: '30px',
                  backgroundColor: '#171725',
                }}
              >
                <div
                  className="chat-container px-0"
                  style={{
                    height: '600px',
                    paddingBottom: '100px',
                    maxWidth: '90vw',
                    minWidth: '240px',
                    width: '100%',
                    borderWidth: '0px',
                    borderTopRightRadius: '15px',
                    borderTopLeftRadius: '15px',
                  }}
                >
                  <div className="chat-messages">
                    {messages.map((message, index) => (
                      <div
                        key={index}  // Use message.id instead of index for better performance
                        className={`message ${message.sender === 'me' ? 'sent' : 'received'
                          }`}
                        style={
                          message.sender === 'me'
                            ? messageStyleSend
                            : messageStyleRec
                        }
                      >
                        {/* <TypingEffect text={transcript} /> */}
                        <TypingEffect text={message.text} />
                      </div>
                    ))}
                    {audioSrc !== '' ?
                      <audio ref={audioRef} controls key={audioSrc}>
                        <source src={audioSrc} type="audio/wav" />
                        Your browser does not support the audio tag.
                      </audio> : ''
                    }

                    {chkk.length > 0 ? (
                      <p style={messageStyleRec} className="message received">
                        {chkk}
                      </p>
                    ) : null}
                    <div className="form-group d-flex justify-content-center mt-4">
                      {loading ? (
                        <ThreeDots color="#fff" height={50} width={50} />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <form
                  className="chat-input mt-5 d-flex justify-end rounded-bottom-4"
                  style={{
                    minHeight: '50px',
                    backgroundColor: '#171725',
                    height: '50px',
                    maxWidth: '90vw',
                    minWidth: '240px',
                    width: '100%',
                    bottom: '-2px',
                    position: 'absolute',
                    borderColor: 'black',
                    borderWidth: '2px',
                  }}
                >
                  {/* <div className="me-2">
                {speechTog === false ? (
                  <button onClick={handleToggleSpeech} type="button">
                    <HiSpeakerXMark />
                  </button>
                ) : (
                  <button onClick={handleToggleSpeech} type="button">
                    <HiSpeakerWave />
                  </button>
                )}
              </div> */}

                  <div className="audio-controls me-2 d-flex align-items-center">
                    {!permission ? (
                      <button onClick={getMicrophonePermission} style={{ display: 'flex', alignItems: 'center' }} type="button">
                        <FaMicrophoneSlash className='me-2' /> Allow Microphone
                      </button>
                    ) : null}
                    {permission && recordingStatus === 'inactive' ? (
                      <button onClick={startRecording} style={{ display: 'flex', alignItems: 'center' }} type="button">
                        <FaMicrophone className='me-2' /> Start Recording
                      </button>
                    ) : null}
                    {recordingStatus === 'recording' ? (
                      <button onClick={stopRecording} style={{ display: 'flex', alignItems: 'center' }} type="button">
                        <BsStopCircle className='me-2' /> End Recording
                      </button>
                    ) : null}
                  </div>

                  {/* <button type="submit">
                <img
                  src={sendIcon}
                  alt="Send"
                  style={{
                    height: '22px',
                    width: '25px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </button> */}
                </form>
                <div>


                </div>
              </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
          </div>
        </div>
      </div>

    </div>
  );
};



export default TextChat;



// useEffect(() => {
//   const recognitionInstance = new window.webkitSpeechRecognition();
//   recognitionInstance.lang = 'en-US';
//   recognitionInstance.interimResults = false;
//   recognitionInstance.maxAlternatives = 1;
//   recognitionInstance.continuous = true;
 
//   recognitionInstance.onresult = (e) => {
//     // Get the most recent transcription (last element in the results)
//     let lastTranscript = e.results[e.results.length - 1][0].transcript;
  
//     console.log('Last transcript:', lastTranscript, e.results);
  
//     // Check if the last transcript contains the magic word
//     if (lastTranscript.includes(magicWord)) {
//       console.log('Magic word detected in last transcript:', lastTranscript);
//       setMagicWordDetected(true);
      
//       // Optionally reset recognition or transcripts here if needed
//     } else {
//       setMagicWordDetected(false);
//     }
//   };

//   recognitionInstance.onend = () => {
//     isRecognitionActive.current = false;
//     setIsListening(false);
//   };

//   setRecognition(recognitionInstance);
//   return () => recognitionInstance.stop();
// }, [magicWord]);