// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pulse{
    /* width: 100px; */
    /* height: 100px; */
    background: #FF6D4A;
    animation: animate-pulse 3s linear infinite;
    cursor: pointer
}
@keyframes animate-pulse{
    0%{
        box-shadow: 0 0 0 0 rgba(255,109,74,0.7),  0 0 0 0 rgba(255,109,74,0.7);
    }
    40%{
        box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 0 rgba(255,109,74,0.7);
    }
    80%{
        box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
    }
    100%{
        box-shadow: 0 0 0 0 rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Chatpages/c.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,2CAA2C;IAC3C;AACJ;AACA;IACI;QACI,uEAAuE;IAC3E;IACA;QACI,0EAA0E;IAC9E;IACA;QACI,2EAA2E;IAC/E;IACA;QACI,wEAAwE;IAC5E;;AAEJ","sourcesContent":["\n.pulse{\n    /* width: 100px; */\n    /* height: 100px; */\n    background: #FF6D4A;\n    animation: animate-pulse 3s linear infinite;\n    cursor: pointer\n}\n@keyframes animate-pulse{\n    0%{\n        box-shadow: 0 0 0 0 rgba(255,109,74,0.7),  0 0 0 0 rgba(255,109,74,0.7);\n    }\n    40%{\n        box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 0 rgba(255,109,74,0.7);\n    }\n    80%{\n        box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);\n    }\n    100%{\n        box-shadow: 0 0 0 0 rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
