import React, { useEffect, useState, useRef, useCallback } from 'react';
import './chatCss.css';
import './c.css'
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2';
import { BsStopCircle } from 'react-icons/bs';
import sendIcon from './send.webp';
import PCMPlayer from 'pcm-player'

import { io } from 'socket.io-client';

function TypingEffect({ text }) {
  return <div>{text}</div>;
}

const AudioChat = () => {

  const [transcript, setTranscript] = useState('');
  // const [audioChunks, setAudioChunks] = useState([]);

  let audioContext; // Singleton for AudioContext

  const getAudioContext = () => {
    if (!audioContext) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Create an AudioContext
    }
    return audioContext; // Return the singleton instance
  };

  // const player = new PCMPlayer({
  //   inputCodec: 'Int16',
  //   channels: 1,
  //   sampleRate: 24000,
  //   flushTime: 200 // Lower to 100-200 ms if 2000 ms causes lag
  // });




  var player = new PCMPlayer({
    inputCodec: 'Int16',
    channels: 1,
    sampleRate: 24000,
    flushTime: 200,
    onstatechange: function() {
      // console.log('State changed to:', previousCurrentTime);
    },
    onended: function () {
      if (startMonitorReco === false) {
        setStartMonitorReco(true);
        console.log('PCMPlayer has finished sending data');
        monitor();
      }
    }
  });
  

  let previousCurrentTimeM = 0;
let lastCurrentTimeM = 0;
let currentTimeCheckIntervalM;
let timeSinceLastUpdateM = 0;

const monitor = () => {
  // Increment previousCurrentTime to simulate updates
  previousCurrentTimeM++;
  console.log("monitor-", previousCurrentTimeM);

  // Check if currentTimeCheckInterval already exists
  if (!currentTimeCheckIntervalM) {
    // Set up a 5-second interval to monitor changes in previousCurrentTime
    currentTimeCheckIntervalM = setInterval(() => {
      if (previousCurrentTimeM === lastCurrentTimeM) {

        console.log("Final stoppage: previousCurrentTime has not increased",listeningNow,listeningNowPermission );
        setListeningNow(true)
        previousCurrentTimeM = 0
        lastCurrentTimeM = 0
        timeSinceLastUpdateM = 0
        startListening()
        setStartMonitorReco(true)
        clearInterval(currentTimeCheckIntervalM);
        currentTimeCheckIntervalM = null;

      

      } else {
        lastCurrentTimeM = previousCurrentTimeM;
      }
    }, 2000); // 5 seconds
  }
};
  

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64); // Decode the base64 string
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer; // Return the ArrayBuffer
  };

  const [canswer, setCanswer] = useState('')
  const [fullMessage, setFullMessage] = useState(""); // Use state for fullMessage
  const [socketOnChk,setSocketOnChk]=useState(false)

  const socket = io('https://aivirtualops.com');
  // const socket = io('http://localhost:5000');

//   useEffect(()=>{
//     console.log(player,"-----------------------------------91")
//  },[player])


  const audioContextRef = useRef(null);
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

  audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();


  let audioQueue = [];
  let isPlaying = false;



    function processQueue() {
    if (audioQueue.length > 0 && !isPlaying) {
      isPlaying = true;
      const audioChunk = audioQueue.shift(); // Get the next chunk
  
      player.feed(audioChunk)  
    
  
      // Set a timeout based on the chunk duration to allow feeding the next chunk
      setTimeout(() => {
        isPlaying = false;
        processQueue(); // Process the next chunk in the queue
      }, audioChunk.length / player.sampleRate * 1000); // Adjust timeout based on chunk duration
    }
  }
  

  

  // Socket listener for audio data
  
  
  socket.on('audio', (audioChunk) => {
    // Decode the base64 audio chunk
    const byteCharacters = atob(audioChunk);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    
    // Convert Uint8Array to Int16Array if needed
    const int16Array = new Int16Array(byteNumbers.buffer); // For 16-bit PCM data
    
    // Add to the queue and start processing if not already
    audioQueue.push(int16Array);
    processQueue();
    console.log('start listening again')
   
  });

    const handleTextReceived = (text) => {
      // console.log('fill ms ', fullMessage, "--", inputValue, "----", canswer);

      // Reset inputValue and canswer
      setInputValue('');
      setCanswer('');

      // console.log('Received text transcript:', text);

      // If you want to start fresh, reset fullMessage before concatenation
      // Alternatively, you can keep the resetting logic based on your specific use case
      setFullMessage(prevMessage => {
        // Concatenate the new fragment to the full message
        const newFullMessage = prevMessage + text;
        setCanswer(newFullMessage);
        return newFullMessage; // Update the state with the new full message
      });
    };

    socket.on('text', handleTextReceived);
   
    // Cleanup on component unmount
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('audio');
      socket.off('text');
    };
  }, []);

  const playAudio = (audioChunk) => {
    audioContextRef.current.decodeAudioData(audioChunk, (buffer) => {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    }, (error) => {
      console.error('Error decoding audio data:', error);
    });
  };

  const BACKEND = process.env.REACT_APP_URL;
  const audioRef = useRef(null);

  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  // const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [speechTog, setSpeechTog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioSub, setAudioSub] = useState(false);
  const [chkk, setChkk] = useState('');
  const [chatbotMsg, setChatbotMsg] = useState('');
  const [audioSrc, setAudioSrc] = useState('')

  const [listeningNow,setListeningNow] = useState(false)
  const [listeningNowPermission,setListeningNowPermission] = useState(false)
  const [startMonitorReco,setStartMonitorReco]= useState(false)

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.');
    }
  };


  const [isListening, setIsListening] = useState(false);
  const [magicWordDetected, setMagicWordDetected] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const isRecognitionActive = useRef(false);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const magicWord = "okay";
  const recordDuration = 5000; // 15 seconds
  const recordTimeoutRef = useRef(null);

  useEffect(() => {
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.lang = 'en-US';
    recognitionInstance.interimResults = false;
    recognitionInstance.maxAlternatives = 1;
    recognitionInstance.continuous = true;
   
    if(listeningNow !== true){

    recognitionInstance.onstart = (e) => {
      console.log('statttttttt')
      setListeningNow(true)
      setListeningNowPermission(true)
      startRecording();

    };
  }


    recognitionInstance.onresult = (e) => {
      console.log('aaaa')
      setListeningNow(false)
      stopRecording()
    };

    recognitionInstance.onend = () => {
      isRecognitionActive.current = false;
      setIsListening(false);
    };

    setRecognition(recognitionInstance);
    return () => recognitionInstance.stop();
  }, [magicWord]);


  const startRecognitionProcess=()=>{
    console.log('startRecognitionProcess')
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.lang = 'en-US';
    recognitionInstance.interimResults = false;
    recognitionInstance.maxAlternatives = 1;
    recognitionInstance.continuous = true;
   

     recognitionInstance.onstart = (e) => {
      console.log('statttttttt')
      setListeningNow(true)
      setListeningNowPermission(true)
      startRecording();

    };

    recognitionInstance.onresult = (e) => {
      console.log('aaaa')
      setListeningNow(false)
      stopRecording()
    };

    recognitionInstance.onend = () => {
      isRecognitionActive.current = false;
      setIsListening(false);
    };

    recognitionInstance.start()

    setRecognition(recognitionInstance);
    return () => recognitionInstance.stop();
  }

  const startListening = useCallback(() => {
    console.log('start listening---33')
    startRecognitionProcess()
    console.log('226---',isRecognitionActive.current,recognition)
    if (!isRecognitionActive.current && recognition) {
      try {

     
       

        recognition.start();
        isRecognitionActive.current = true;
        
        setIsListening(true);
        // startRecording();
      } catch (e) {
        console.error("Error starting speech recognition", e);
      }
    }
  }, [recognition]);

  const stopListening = useCallback(() => {
    if (isRecognitionActive.current && recognition) {
      recognition.stop();
      isRecognitionActive.current = false;
    }
  }, [recognition]);

  const startRecording = () => {
    console.log('start recording')
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        if (magicWordDetected) { // Reset magic word detected to prevent re-triggering
          setMagicWordDetected(false);
        }
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunks.current = [];
        mediaRecorderRef.current.ondataavailable = event => {
          console.log('on avail---370')
          if (event.data.size > 0) audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          console.log('on stop---373')
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
          const audioUrl = URL.createObjectURL(audioBlob);



          if (audioBlob) {
            const formData = new FormData();
            formData.append('audio', audioBlob, 'recording.webm');
          
            console.log("Audio Blob:", audioBlob,magicWordDetected);
            console.log("Form Data:", formData.get('audio'));
            setMagicWordDetected(false)
            // Immediately add the message before the response is received
            const newMessageI = {
              id: messages.length,
              text: inputValue, // This should be the query you sent
              sender: 'me',
            };
            if(newMessageI.text !==''){
              setMessages((prevMessages) => [...prevMessages, newMessageI]);
            }
    
            axios
              .post(`${BACKEND}/api/send-audio`, formData, {
                headers: {
                  'Content-type': 'multipart/form-data',
                },
              })
              .then((res) => {
                console.log('res.data ', res.data);
                setLoading(false);
                // Update the input value with the response transcript
                setInputValue(res.data.transcript);
    
                // If you want to handle the chatbot response, you can also add it here
    
                const chatbotMessage = {
                  id: messages.length + 1, // Incrementing ID for chatbot message
                  text: res.data.input, // Chatbot response text
                  sender: 'me', // Assuming sender is empty for chatbot
                };
                if(chatbotMessage !==''){
                  setMessages((prevMessages) => [...prevMessages, chatbotMessage]);
                }
    
                const uniqueParam = new Date().getTime();
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
    
          }


          setAudioUrl(audioUrl);
          audioChunks.current = [];
        };
        mediaRecorderRef.current.start();

        // Set a timeout to stop recording after 15 seconds
        // recordTimeoutRef.current = setTimeout(() => {
        //   stopRecording();
        //   setMagicWordDetected(false);
        // }, recordDuration);
      })
      .catch(e => console.error("Microphone error: ", e));
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      // clearTimeout(recordTimeoutRef.current);
    }
  };

  useEffect(()=>{
  // console.log('plate------------',player.destroy())
  },[player])

  useEffect(() => {
 console.log('message======= ',messages)
 isRecognitionActive.current = false;
    console.log('----------220 ', messages[messages.length - 1])
    if (messages[messages.length - 1]?.sender === 'me' && canswer !== messages[messages.length - 1]?.text) {
      console.log('----------230 ')
      const chatbotMessage = {
        id: messages.length, // Incrementing ID for chatbot message
        text: inputValue, // Chatbot response text
        sender: '', // Assuming sender is empty for chatbot
      };
      if(chatbotMessage.text !== ''){
        setMessages((prevMessages) => [...prevMessages, chatbotMessage]);
      }
      setCanswer('')
      setInputValue('')
      // startListening()
      setStartMonitorReco(true)



    }
  }, [messages])

  const handleToggleSpeech = () => {
    setSpeechTog((prevTog) => !prevTog);
  };

  const messageStyleSend = {
    backgroundColor: '#f0f8ff',
    fontSize: '16px',
    color: '#000000',
    fontFamily: 'Arial, sans-serif',
  };

  const messageStyleRec = {
    backgroundColor: '#e6e6fa',
    fontSize: '16px',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  };





  return (
    <div>
      <div className=' ' style={{ paddingTop: '100px', paddingBottom: '100px', minWidth: '90vw' }}>
      <h1 className=" flex-wrap col-12 d-flex justify-content-center text-center" style={{ color: 'black' }}>Speech to Speech Demo</h1>
      {/* <h1 className=" flex-wrap col-12 d-flex justify-content-center text-center" style={{ color: 'black' }}>{listeningNow ? 'list now t': 'list now f'} </h1> */}
      {/* <h1 className=" flex-wrap col-12 d-flex justify-content-center text-center" style={{ color: 'black' }}> {listeningNowPermission ? 'lnp t':'lnp f'}</h1> */}

        <div>
      {/* <h1 className='d-flex justify-center'>Magic Word Listener</h1> */}
      {/* <p>Status: {isListening ? "Listening..." : "Idle"}</p>
      {magicWordDetected && <p>Magic Word Detected!</p>}
      {audioUrl && <audio controls src={audioUrl} />}
      <button onClick={startListening}>Start Listening</button>
      <button onClick={stopListening}>Stop Listening</button> */}
    </div>
        <div className='col-12 flex justify-center'>
          <div className="row d-flex justify-content-around flex-wrap " style={{ minWidth: '90vh' }}>
            <div className="d-flex justify-content-center my-5 col-lg-10 col-11">
              <div
                className={`d-flex ${listeningNow ? 'pulse' : ''}`}
                style={{
                  position: 'relative',
                  height: '600px',
                  minWidth: '240px',
                  maxWidth: '90vw',
                  width: '100%',
                  marginTop: '30px',
                  backgroundColor: '#171725',
                }}
              >
                <div
                  className="chat-container px-0"
                  style={{
                    height: '600px',
                    paddingBottom: '100px',
                    maxWidth: '90vw',
                    minWidth: '240px',
                    width: '100%',
                    borderWidth: '0px',
                    borderTopRightRadius: '15px',
                    borderTopLeftRadius: '15px',
                  }}
                >
                  <div className="chat-messages">
                    {messages.map((message, index) => (
                      <div
                        key={index}  // Use message.id instead of index for better performance
                        className={`message ${message.sender === 'me' ? 'sent' : 'received'
                          }`}
                        style={
                          message.sender === 'me'
                            ? messageStyleSend
                            : messageStyleRec
                        }
                      >
                        {/* <TypingEffect text={transcript} /> */}
                        <TypingEffect text={message.text} />
                      </div>
                    ))}
                    {audioSrc !== '' ?
                      <audio ref={audioRef} controls key={audioSrc}>
                        <source src={audioSrc} type="audio/wav" />
                        Your browser does not support the audio tag.
                      </audio> : ''
                    }

                    {chkk.length > 0 ? (
                      <p style={messageStyleRec} className="message received">
                        {chkk}
                      </p>
                    ) : null}
                    <div className="form-group d-flex justify-content-center mt-4">
                      {listeningNow === false && listeningNowPermission === true ? (
                        <ThreeDots color="#fff" height={50} width={50} />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <form
                  className="chat-input mt-5 d-flex justify-end rounded-bottom-4"
                  style={{
                    minHeight: '50px',
                    backgroundColor: '#171725',
                    height: '50px',
                    maxWidth: '90vw',
                    minWidth: '240px',
                    width: '100%',
                    bottom: '-2px',
                    position: 'absolute',
                    borderColor: 'black',
                    borderWidth: '2px',
                  }}
                >
                  {/* <div className="me-2">
                {speechTog === false ? (
                  <button onClick={handleToggleSpeech} type="button">
                    <HiSpeakerXMark />
                  </button>
                ) : (
                  <button onClick={handleToggleSpeech} type="button">
                    <HiSpeakerWave />
                  </button>
                )}
              </div> */}

                  <div className="audio-controls me-2 d-flex align-items-center">
                    {listeningNowPermission === false  ? (
                      <button  onClick={startListening} style={{ display: 'flex', alignItems: 'center' }} type="button">
                      <FaMicrophoneSlash className='me-2' /> Allow Microphone
                    </button>
                    // {/*   <button onClick={getMicrophonePermission} style={{ display: 'flex', alignItems: 'center' }} type="button">
                    //     <FaMicrophoneSlash className='me-2' /> Allow Microphone
                    //      </button> */}
                    ) : null} 
                    {listeningNow === true ? (
                      <button onClick={startRecording} style={{ display: 'flex', alignItems: 'center' }} type="button">
                        <FaMicrophone className='me-2' /> Recording
                      </button>
                    ) : null}
                    {listeningNow === false && listeningNowPermission === true ? (
                      <button onClick={stopRecording} style={{ display: 'flex', alignItems: 'center' }} type="button">
                        <BsStopCircle className='me-2' /> Generating Answer
                      </button>
                    ) : null}
                  </div>

                  {/* <button type="submit">
                <img
                  src={sendIcon}
                  alt="Send"
                  style={{
                    height: '22px',
                    width: '25px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </button> */}
                </form>
                <div>


                </div>
              </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
          </div>
        </div>
      </div>

    </div>
  );
};



export default AudioChat;
